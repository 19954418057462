import React from 'react';
import {Link} from 'react-router-dom';

import nurse from './../../../images/nurse.jpg';

const StaffProfile = () => {
    return (
        <>
            <div className="text-end mb-3">
                <Link to={"#"} className="btn btn-primary btn-rounded add-staff">Staff Detail's</Link>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="profile">
                                        <div className="staff">
                                            <img src={nurse} alt="" />
                                        </div>
                                        <div className="staf-info">
                                            <div>
                                                <span>Name:</span><h2>Kate Velasquez</h2>
                                                <div>
                                                    <span className="">Designation:</span><span className="fs-18">Nurse</span>
                                                </div>
                                                <span className="">Gender:</span><span className="fs-18">Female</span>
                                                <div>
                                                    <span className="">Degrer:</span><span className="fs-18">BSN</span>
                                                </div>
                                            </div>
                                            <div className="location mt-4">
                                                <div className="mb-3">
                                                    <span><i className="fa fa-map-marker me-2"></i> San Francisco, USA</span>
                                                    <span><i className="fa fa-building me-2"></i> ICU Department</span>
                                                </div>
                                                <div>
                                                    <span><i className="fa fa-phone me-2"></i> +1 1234598767</span>
                                                    <span><i className="fa fa-envelope me-2"></i>kate.velasquez@gmail.com</span>
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-content-between border-bottom mb-3">
                                        <span className="font-w600 text-black">Followers</span>
                                        <span>500</span>
                                    </div>
                                    <div className="d-flex justify-content-between border-bottom mb-3">
                                        <span className="font-w600 text-black">Following</span>
                                        <span>300</span>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <span className="font-w600 text-black">Friends</span>
                                        <span>300</span>
                                    </div>
                                    <div className="mt-4">
                                        <Link to={"#"} className="btn btn-primary me-3">Follow</Link>
                                        <Link to={"#"} className="btn btn-primary">Message</Link>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mt-5">
                                        <h2>About Me</h2>
                                        <p className="fs-14">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </p>
                                        <p className="fs-14">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                                        </p>
                                    </div>
                                    <hr/>
                                </div>
                                
                                <div className="col-lg-12">
                                    <h2>Responsibilities</h2>
                                    <p className="fs-14">Identify patients’ care requirements, focus on their needs and act on them</p>
                                    <p className="fs-14">Nurture a compassionate environment by providing psychological support</p>
                                    <p className="fs-14">Resolve or report on patients’ needs or problems</p>
                                    <p className="fs-14">Prepare patients for examinations and perform routine diagnostic checks (monitor pulse, blood pressure and temperature, provide drugs and injections etc)</p>
                                    <p className="fs-14">Monitor and record patient’s condition and document provided care services</p>
                                    <p className="fs-14">Treat medical emergencies</p>
                                    <hr/>
                                </div>
                                <div className="col-lg-12">
                                    <h2>Experience</h2>
                                    <p className="fs-14">Lorem Ipsum is simply dummy text of the printing and typesetting industrypsychological suppor.</p>
                                    <p className="fs-14">Nurture a compassionate environment by providing psychological support</p>
                                    <p className="fs-14">Lorem Ipsum is simply dummy text of the printing and typesetting industry psychological suppor.</p>
                                    <p className="fs-14">PLorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p className="fs-14">Monitor and record patient’s condition and document provided care services</p>                                
                                    <hr/>
                                </div>
                                <div className="col-lg-12">
                                    <h2>Education</h2>
                                    <p className="fs-14">Lorem Ipsum is simply dummy text of the printing and typesetting industrypsychological suppor.</p>
                                    <p className="fs-14">Nurture a compassionate environment by providing psychological support</p>
                                    <p className="fs-14">Lorem Ipsum is simply dummy text of the printing and typesetting industry psychological suppor.</p>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StaffProfile;