const BOX_STATUS = {
    ASSIGNED: '8f9c8e02-b9ee-4f29-aeea-670cef022663',
    STOCKED: '4ee90c0f-d4c3-4d53-8014-d7eac4ea2894',
    HOLD: '45593c2c-fc8c-4bf0-854e-e2bf759b7788',
    PICKED_UP: '00fe15e3-80b9-4e6f-9bf3-ad0c09a58c3e',
    CANCELLED: '8a994741-402e-4b18-a883-60da7fc94c7b',
    RETURNED: '198473ec-2e3f-42c8-a3f9-a0a3f8b4ae55',
    DEACTIVATED: '4f666da5-bfe0-4fb5-ad77-b92103881220',
    REMOVE_RX:'1708e022-a093-4cd2-a48a-eec80ded6c91'
}

export default BOX_STATUS