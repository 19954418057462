/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, Suspense, useEffect, } from 'react';
import Index from './jsx/index';
import { Route, Routes, useLocation, } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/style.css";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setUser } from './redux/userSlice';
import { setAuth } from './redux/authSlice';

const Login = lazy(() => import('./jsx/pages/Login'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const ResetPassword = lazy(()=>import('./jsx/pages/ResetPassword'));

function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuth = useSelector(state => state.authReducer.isAuth);

    useEffect(() => {
        if (location.pathname === '/') {
            for (let key of Object.keys(localStorage)) {
                localStorage.removeItem(key);
            }
            for (let key of Object.keys(sessionStorage)) {
                sessionStorage.removeItem(key);
            }
            dispatch(setUser({ user: null }));
            dispatch(setAuth({ isAuth: false }));
        }
    }, [])

    let routeblog = (
        <Routes>
            <Route path='' element={<Login />} />
            <Route path='/forget-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
        </Routes>
    );
    if (isAuth) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    rtl={false}
                    theme="light"
                />
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routeblog}
                </Suspense>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    rtl={false}
                    theme="light"
                />
            </div>
        );
    }
};

export default App; 
