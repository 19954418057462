/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import request from "../../../services/AxiosInstance";
import { Dropdown } from "react-bootstrap";
import { getCurrentMonthDates, getCurrentYearDates, getLastThreeMonthsDates } from "../../../helper/helper";

const RevenueByPeriod = () => {
    const [series, setSeries] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [timeLabel, setTimeLabel] = useState('This Month');
    const [loading, setLoading] = useState(true);

    const options = {
        chart: {
            height: 350,
            type: "area",
            group: "social",
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [2, 2],
            colors: ["#F46B68", "#2BC155"],
            curve: "straight",
        },
        legend: {
            tooltipHoverFormatter: function (val, opts) {
                return (
                    val +
                    " - " +
                    opts.w.globals.series[opts.seriesIndex][
                    opts.dataPointIndex
                    ] +
                    ""
                );
            },
            markers: {
                fillColors: ["#F46B68", "#2BC155"],
                width: 19,
                height: 19,
                strokeWidth: 0,
                radius: 19,
            },
        },
        markers: {
            size: 6,
            border: 0,
            colors: ["#F46B68", "#2BC155"],
            hover: {
                size: 6,
            },
        },
        xaxis: {
            categories: [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
            ],
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#3e4954",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: 100,
                },
            },
        },
        fill: {
            colors: ["#F46B68", "#2BC155"],
            type: "solid",
            opacity: 0.07,
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    const getData = useCallback(async () => {
        if (!startDate || !endDate) return;

        setLoading(true);

        const reqObj = {
            fromdate: startDate,
            todate: endDate,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        try {
            const resData = await request('post', 'apis/i-ecom/status/dashlateTime', reqObj);

            if (resData.message) {
                console.error(resData.message);
                return;
            }

            const orderCount = resData.map(item => item.rx_count);
            const customerCount = resData.map(item => item.customer_count);

            setSeries([
                {
                    name: "Order Count",
                    data: orderCount,
                },
                {
                    name: "CustomerCount",
                    data: customerCount,
                },
            ]);
        } catch (error) {
            console.error("Failed to fetch data", error);
        } finally {
            setLoading(false);
        }
    }, [startDate, endDate]);

    const handleChange = (type) => {
        let dates;
        let label;

        if (type === 1) {
            dates = getCurrentMonthDates();
            label = "This Month";
        } else if (type === 2) {
            dates = getLastThreeMonthsDates();
            label = "Last 3 Months";
        } else if (type === 3) {
            dates = getCurrentYearDates();
            label = "This Year";
        }

        setStartDate(dates.start);
        setEndDate(dates.end);
        setTimeLabel(label);
    };

    useEffect(() => {
        // handleChange(1);
    }, []);

    useEffect(() => {
        // getData();
    }, [startDate, endDate, getData]);

    return (
        <div className="card">
            <div className="card-header border-0 pb-0">
                <h3 className="fs-20 mb-0 text-black">Revenue by period</h3>
                <Dropdown className="dropdown d-inline-block">
                    <Dropdown.Toggle variant="" className="btn-link text-primary dropdown-toggle p-0 mb-0 fs-14 text-primary">
                        <span className="font-w500">{timeLabel}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-left">
                        <Dropdown.Item className="dropdown-item" onClick={() => handleChange(1)}>This Month</Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" onClick={() => handleChange(2)}>Last 3 Months</Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" onClick={() => handleChange(3)}>This Year</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="card-body pb-0">
                <div className="chart-warp" style={{ overflow: "hidden" }}>
                    <div id="chart">
                        {/* {loading ? (
                            <p>Loading...</p>
                        ) : ( */}
                            <ReactApexChart
                                options={options}
                                series={series}
                                type="area"
                                height={400}
                            />
                        {/* )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RevenueByPeriod;
