import React, { forwardRef } from "react";
import QRCode from "react-qr-code";
import { Container, Row, Col } from "react-bootstrap";

const RetailLabelTemplate = forwardRef((props, ref) => {
    const { boxId, accountDetails, boxDetails, connectionType, ProfileNumber, orderDetails } = props;
    let location = {
        picture_url: "",
        name: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
    };

    let _boxDetails = boxDetails && boxDetails.find((x) => x.id === boxId);
    // let brandingName = "", brandingPhone = "";
    const getBranding = boxDetails.find((ele) => ele.id === boxId);

    if (getBranding) {
        // const { brandName, brandPhone } = getBranding;
        // brandingName = brandName;
        // brandingPhone = brandPhone;
    }

    if (_boxDetails) {
        location.picture_url = _boxDetails.picture_url;
        location.name =
            connectionType === 0 ? _boxDetails.name : _boxDetails.address.name;
        location.address_line_1 =
            connectionType === 0 ? _boxDetails.address_line_1 : _boxDetails.address.address_line_1;
        location.address_line_2 =
            connectionType === 0 ? _boxDetails.address_line_2 : _boxDetails.address.address_line_2;
        location.city =
            connectionType === 0 ? _boxDetails.city : _boxDetails.address.city;
        location.state =
            connectionType === 0 ? _boxDetails.state : _boxDetails.address.state;
        location.country =
            connectionType === 0 ? _boxDetails.country : _boxDetails.address.country;
        location.zip =
            connectionType === 0 ? _boxDetails.zip : _boxDetails.address.zip;
    }

    let _storageCodes = localStorage.getItem("label-codes");
    let codes = {};
    if (_storageCodes) {
        codes = JSON.parse(_storageCodes);
    }

    return (
        <Container ref={ref}>
            {accountDetails && (
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        {location && (
                            <img
                                style={{ width: "60px", height: "60px" }}
                                alt="logo"
                                src={location.picture_url}
                            />
                        )}
                    </div>
                    <div>
                        <h4>{accountDetails && accountDetails.name}</h4>
                    </div>
                </div>
            )}
            {location && (
                <div>
                    <h4>Deliver To:</h4>
                    <h5>
                        {location.name}<br />
                        {location.address_line_1}<br />
                        {location.address_line_2}
                        {location.city}
                        {location.state}, {location.country}<br />
                        {location.zip}
                    </h5>
                </div>
            )}
            <div>
                <Row>
                    <Col xs={12}>
                        <div style={{ width: "120px", height: "120px", padding: "4px" }}>
                            {codes && codes.stockCode && (
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={codes.stockCode}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h3>{codes && codes.stockCode}</h3>
                    </Col>
                </Row>
                <div style={{ borderBottom: "1px solid #000" }}></div>
                <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                    <Col xs={12}>
                        <span><h4>Profile Id</h4>: {ProfileNumber}</span>
                    </Col>
                </Row>
                <div style={{ borderBottom: "1px solid #000" }}></div>
                <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
                    <Col xs={12}>
                        <span>
                            <h4>Order Detail</h4>
                            {orderDetails &&
                                orderDetails.map((order, index) => (
                                    <div key={index}>{order && order.item}</div>
                                ))}
                        </span>
                    </Col>
                </Row>
                <div style={{ borderBottom: "1px solid #000" }}></div>
            </div>
        </Container>
    );
});

export default RetailLabelTemplate;
