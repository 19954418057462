import { Amplify } from "aws-amplify";

const ConfigureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_CG_REGION,
      userPoolId: process.env.REACT_APP_CG_USERPOOLID,
      userPoolWebClientId: process.env.REACT_APP_CG_WEBCLIENTID,
      // mandatorySignIn: true,
      // storage: sessionStorage,
    },
  });
};

export default ConfigureAmplify;
