import axios from "axios";

const apiService = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getToken = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user?.signInUserSession?.idToken?.jwtToken || '';
};

const request = async (method, url, data = null) => {
  try {
    apiService.defaults.headers['Authorization'] = `Bearer ${getToken()}`;

    const response = await apiService({
      method,
      url,
      data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export default request;
