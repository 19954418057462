import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const SelectBin = ({ binIds, setSelectedBin, selectedBin }) => {
  const [selectLocalBinId, setSelectLocalBinId] = useState([]);
  const [selectedBinId, setSelectedBinId] = useState("");

  const onSelectBin = (bin) => {
    const filterByBin = binIds.filter((x) => x.bin_id === bin);
    let codes = {
      stockCode: filterByBin[0].stock_code,
      pickupCode: filterByBin[0].pickup_code,
    };
    localStorage.setItem("label-codes", JSON.stringify(codes));
    setSelectedBin(filterByBin);
    setSelectedBinId(bin); // Update the selected bin ID
  };

  useEffect(() => {
    const bins = [...new Set(binIds.map((item) => item.bin_id))];
    setSelectLocalBinId(bins);
    if (bins.length > 0) {
      const filterByBin = binIds.filter((x) => x.bin_id === bins[0]);
      let codes = {
        stockCode: filterByBin[0].stock_code,
        pickupCode: filterByBin[0].pickup_code,
      };
      localStorage.setItem("label-codes", JSON.stringify(codes));
      setSelectedBin(filterByBin);
      setSelectedBinId(bins[0]); // Set the first bin ID as selected by default
    }
  }, [binIds, setSelectedBin]);

  return (
    <Container className="p-0">
      {selectLocalBinId &&
        selectLocalBinId.map((x) => {
          return (
            <Row key={x} className="pl-3 mb-2">
              <Form.Check
                type="radio"
                name="binId"
                checked={selectedBinId === x}
                onChange={() => onSelectBin(x)}
                label={x}
              />
            </Row>
          );
        })}
      <Row className="mt-3">
        {selectedBin && (
          <Col>
            <strong>Selected Bin: </strong>
            {selectedBinId}
          </Col>
        )}
      </Row>{" "}
    </Container>
  );
};

export default SelectBin;
