import moment from "moment";

const getCurrentMonthDates = () => {
    const now = new Date();
    const monthStartDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const monthEndDay = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
    return {
        start: moment(monthStartDay).format('YYYY-MM-DD HH:mm:ssZ'),
        end: moment(monthEndDay).format('YYYY-MM-DD HH:mm:ssZ')
    };
};

const getLastThreeMonthsDates = () => {
    const now = new Date();
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    const startMonth = now.getMonth() - 2;
    const startYear = startMonth < 0 ? now.getFullYear() - 1 : now.getFullYear();
    const actualStartMonth = (startMonth + 12) % 12;
    const start = new Date(startYear, actualStartMonth, 1, 0, 0, 0, 0);
    return {
        start: moment(start).format('YYYY-MM-DD HH:mm:ssZ'),
        end: moment(end).format('YYYY-MM-DD HH:mm:ssZ')
    };
};

const getCurrentYearDates = () => {
    const now = new Date();
    const yearStartDate = new Date(now.getUTCFullYear(), 0, 1, 0, 0, 0, 0);
    const yearEndDate = new Date(now.getUTCFullYear(), 11, 31, 23, 59, 59, 999);
    return {
        start: moment(yearStartDate).format('YYYY-MM-DD HH:mm:ssZ'),
        end: moment(yearEndDate).format('YYYY-MM-DD HH:mm:ssZ')
    };
};

const maskPhoneNumber = (value) => {
    if (![undefined, 'undefined', null, 'null', ""].includes(value)) {
        return value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
        return "";
    }
}

const timeMask = (value) => {
    if (![undefined, 'undefined', null, 'null', ""].includes(value)) {
        const numericValue = value.replace(/\D/g, '');
        if (numericValue.length > 2) {
            return numericValue.slice(0, 2) + ":" + numericValue.slice(2, 4);
        } else {
            return numericValue;
        }
    } else {
        return "";
    }
};

export { getCurrentMonthDates, getLastThreeMonthsDates, getCurrentYearDates, maskPhoneNumber, timeMask }

