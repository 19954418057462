import React, { useState } from 'react';
import Main24Layout from './Mini24Layout';
import BOX_TYPE from '../../../constant/BoxTypes';
import { Carousel } from 'react-bootstrap';
import HD36Layout from './HD36Layout';
import L60Layout from './L60Layout';

function BoxLayout(props) {
    const [index, setIndex] = useState(0);
    const {
        expansionUnits,
        getSelectedBin,
        deactivatedBins,
        BinStatus,
        getPrescriptionDetails,
        isADACompatible,
        PatientID,
        ADAData,
        isViewPermissionAllowed,
        boxTypeId
    } = props;

    const getExpansionUnitsByPos = (position) => expansionUnits.filter((x) => x.pos === position);
    const Selectedbin = (binId, position) => getSelectedBin(binId, position);

    const getExpansionsCounts = () => {
        const boxLeftExpansionObjectArray = [];
        const boxRightExpansionObjectArray = [];
        const RightAndLeftExpansions = {};
        for (let unit = 0; unit < expansionUnits.length; unit += 1) {
            if (expansionUnits[unit].box_position < 0) {
                const expansionObj = {
                    expansionId: expansionUnits[unit].id,
                    box_position: Number(expansionUnits[unit].box_position),
                    bin_column_count: expansionUnits[unit].bin_column_count,
                };
                if (
                    expansionObj
                    && expansionObj.expansionId
                    && expansionObj.box_position !== 0
                ) {
                    boxLeftExpansionObjectArray.push(expansionObj);
                }
            } else {
                const expansionObj = {
                    expansionId: expansionUnits[unit].id,
                    box_position: Number(expansionUnits[unit].box_position),
                    bin_column_count: expansionUnits[unit].bin_column_count,
                };
                if (
                    expansionObj
                    && expansionObj.expansionId
                    && expansionObj.box_position !== 0
                ) {
                    boxRightExpansionObjectArray.push(expansionObj);
                }
            }
        }
        RightAndLeftExpansions.leftExpansions = boxLeftExpansionObjectArray.reverse();
        RightAndLeftExpansions.rightExpansions = boxRightExpansionObjectArray;
        return RightAndLeftExpansions;
    };

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const Expansions = getExpansionsCounts();

    return (
        <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}
        >
            {expansionUnits
                && expansionUnits !== undefined
                && expansionUnits.length > 0
                && expansionUnits.map((item) => {
                    const getExpansionUnits = getExpansionUnitsByPos(item.pos);
                    if (item.pos === 'L') {
                        if (item && item.expansion_id === BOX_TYPE.L60) {
                            return (
                                <Carousel.Item>
                                    <L60Layout
                                        key={index}
                                        expansion={item}
                                        prefix={item.pos + item.selectedUnitIndex}
                                        box_position={item.box_position}
                                        Selectedbin={Selectedbin}
                                        BinStatus={BinStatus}
                                        deactivatedBins={deactivatedBins}
                                        getPrescriptionDetails={getPrescriptionDetails}
                                        previousExpansion={getExpansionUnits[(index + getExpansionUnits.length - 1) % getExpansionUnits.length]}
                                        ExpansionsCounts={Expansions}
                                        boxTypeId={boxTypeId}
                                        isViewPermissionAllowed={isViewPermissionAllowed}
                                    />
                                </Carousel.Item>
                            );
                        }
                        if (item && item.expansion_id === BOX_TYPE.HD36) {
                            return (
                                <Carousel.Item>
                                    <HD36Layout
                                        key={index}
                                        expansion={item}
                                        prefix={item.pos + item.selectedUnitIndex}
                                        box_position={item.box_position}
                                        Selectedbin={Selectedbin}
                                        BinStatus={BinStatus}
                                        deactivatedBins={deactivatedBins}
                                        getPrescriptionDetails={getPrescriptionDetails}
                                        previousExpansion={getExpansionUnits[(index + getExpansionUnits.length - 1) % getExpansionUnits.length]}
                                        ExpansionsCounts={Expansions}
                                        boxTypeId={boxTypeId}
                                        isViewPermissionAllowed={isViewPermissionAllowed}
                                    />
                                </Carousel.Item>
                            );
                        }
                    }
                    if (item.pos === 'M24') {
                        return (
                            <Carousel.Item>
                                <Main24Layout
                                    key={index}
                                    expansion={item}
                                    prefix={item.pos + item.selectedUnitIndex}
                                    box_position={item.box_position}
                                    Selectedbin={Selectedbin}
                                    BinStatus={BinStatus}
                                    deactivatedBins={deactivatedBins}
                                    getPrescriptionDetails={getPrescriptionDetails}
                                    isADACompatible={isADACompatible}
                                    PatientID={PatientID}
                                    ADAData={ADAData}
                                    isViewPermissionAllowed={isViewPermissionAllowed}
                                />
                            </Carousel.Item>
                        );
                    }
                    if (item.pos === 'R') {
                        if (item && item.expansion_id === BOX_TYPE.L60) {
                            return (
                                <Carousel.Item>
                                    <L60Layout
                                        key={index}
                                        expansion={item}
                                        prefix={item.pos + item.selectedUnitIndex}
                                        box_position={item.box_position}
                                        Selectedbin={Selectedbin}
                                        BinStatus={BinStatus}
                                        deactivatedBins={deactivatedBins}
                                        getPrescriptionDetails={getPrescriptionDetails}
                                        previousExpansion={getExpansionUnits[(index + getExpansionUnits.length - 1) % getExpansionUnits.length]}
                                        ExpansionsCounts={Expansions}
                                        boxTypeId={boxTypeId}
                                        isViewPermissionAllowed={isViewPermissionAllowed}
                                    />
                                </Carousel.Item>
                            );
                        }
                        if (item && item.expansion_id === BOX_TYPE.HD36) {
                            return (
                                <Carousel.Item>
                                    <HD36Layout
                                        key={index}
                                        expansion={item}
                                        prefix={item.pos + item.selectedUnitIndex}
                                        box_position={item.box_position}
                                        Selectedbin={Selectedbin}
                                        BinStatus={BinStatus}
                                        deactivatedBins={deactivatedBins}
                                        getPrescriptionDetails={getPrescriptionDetails}
                                        previousExpansion={getExpansionUnits[(index + getExpansionUnits.length - 1) % getExpansionUnits.length]}
                                        ExpansionsCounts={Expansions}
                                        boxTypeId={boxTypeId}
                                        isViewPermissionAllowed={isViewPermissionAllowed}
                                    />
                                </Carousel.Item>
                            );
                        }
                    }
                    return true;
                })}
        </Carousel>
    );
}

export default BoxLayout;
