const EventTypes = {
    ASSIGNED: 'ASSIGNED',
    STOCKED: 'STOCKED',
    PICKED_UP: 'PICKED_UP',
    RX_EXPIRED:'RX_EXPIRED',
    REMOVE_RX: 'REMOVE_RX',
    VERFIY_RX: 'VERFIY_RX',
    RX_HOLD: 'RX_HOLD',
    MANUAL_OVERRIDE:'MANUAL OVERRIDE',
    MOVE: 'MOVE',
    Ready: 'Ready',
    DELIVER: 'DELIVERED',
    COMPLETE: 'COMPLETED',
    RETURN: 'RETURNED',
    CANCEL: 'CANCEL',
    Out_for_Delivery: 'OUT_FOR_DELIVERY',
    ARRIVED: 'ARRIVED'
  };

  export default EventTypes;
  