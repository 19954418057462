import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import request from './../../../services/AxiosInstance';
import { Button, Modal, Overlay } from "react-bootstrap";
import { Loader } from "../../components/bootstrap/Loader";
import { RiAddCircleFill } from "react-icons/ri";
import Product from "./Product";


const ProductList = () => {
    const navigate = useNavigate();
    const [type, setType] = useState('')
    const [productData, setProductData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [productRowData, setProductRowData] = useState('')
    const [modal, setModal] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [searchText, setSearchText] = useState('');

    const handleProductDelete = async () => {
        setLoader(true);
        const rowIdToDelete = productRowData && productRowData[0].id
        const deletedRes = await request('delete', `apis/i-ecom/ecom_product/${rowIdToDelete}`, {})
        if (deletedRes.message) {
            toast.error('Error while deleting !!')
            setLoader(false);
            setModal(false);
            return;
        }
        setLoader(false);
        setModal(false);
        const newData = productData.filter((x) => x.id !== rowIdToDelete);
        setProductData(newData)
    }



    const ActionCellRenderer = ({ data }) => {
        const [show, setShow] = useState(false);
        const target = useRef(null);

        const handleView = () => {
            if (isView) {
                const _data = productData && productData.filter((val) => val.id === data.id)
                if (_data.length > 0) {
                    setType('View')
                    setProductRowData(_data);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        };

        const handleEdit = () => {
            if (isEdit) {
                const _data = productData.filter((val) => val.id === data.id)
                if (_data.length > 0) {
                    setType('Edit');
                    setProductRowData(_data);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        };

        const handleDelete = () => {
            if (isDelete) {
                const _data = productData.filter((val) => val.id === data.id)
                if (_data.length > 0) {
                    setProductRowData(_data);
                    setModal(true);
                } else {
                    toast.error('Data Not Found!')
                }
            }
        }

        return (
            <>
                <Button variant="link" className='pr-0' ref={target} onClick={() => setShow(!show)}>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </Button>
                <Overlay target={target.current} show={show} placement="right">
                    {({ placement: _placement,
                        arrowProps: _arrowProps,
                        show: _show,
                        popper: _popper,
                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                        ...props }) => (
                        <div className='action-btn'
                            {...props}
                        >
                            <span
                                className={`action-link ${isView ? '' : 'cursor-na'}`}
                                onClick={() => handleView()}
                            >
                                View
                            </span>
                            <span
                                className={`action-link ${isEdit ? '' : 'cursor-na'}`}
                                onClick={() => handleEdit()}
                            >
                                Edit
                            </span>
                            <span
                                className={`action-link ${isDelete ? '' : 'cursor-na'}`}
                                onClick={() => handleDelete()}
                            >
                                Delete
                            </span>
                        </div>
                    )}
                </Overlay>
            </>
        );
    }

    const getProductList = (TransactionFilterObj) => {
        request('post', 'apis/i-ecom/ecom_product/getProduct', TransactionFilterObj)
            .then(response => {
                if (response && response.results) {
                    if (response.results.length > 0) {
                        setProductData(response.results)
                    } else {
                        setProductData([])
                    }
                } else if (response && response.message) {
                    toast.error('Error while fetching product');
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const handleSearch = (e) => {
        const searchText = e.target.value
        setSearchText(searchText)
        if (searchText.length > 3) {
            let TransactionFilterObj = {
                searchtext: searchText,
                pageNo: 0,
                pageSize: 10,
                sortOrder: "desc",
                sortColumn: "created_on",
                tableName: "",
            }
            getProductList(TransactionFilterObj)
        } else {
            let TransactionFilterObj = {
                searchtext: '',
                pageNo: 0,
                pageSize: 10,
                sortOrder: "desc",
                sortColumn: "created_on",
                tableName: "",
            }
            getProductList(TransactionFilterObj)
        }
    }

    const handlePermissions = () => {
        // const permissions = JSON.parse(sessionStorage.getItem("permissions"));

        // if (!permissions || permissions.length === 0) {
        //     setIsAdd(false);
        //     setIsView(false);
        //     setIsEdit(false);
        //     setIsDelete(false);
        //     return;
        // }

        // const manageBoxes = permissions.filter(item => item.permission_entity_type === "Manage Boxes");

        // if (manageBoxes.length === 0) {
        //     setIsAdd(false);
        //     setIsView(false);
        //     setIsEdit(false);
        //     setIsDelete(false);
        //     return;
        // }

        // setIsAdd(manageBoxes.some(item => item.name.trim() === 'Add' && item.is_allowed === 1));
        // setIsView(manageBoxes.some(item => item.name.trim() === 'View' && item.is_allowed === 1));
        // setIsEdit(manageBoxes.some(item => item.name.trim() === 'Modify' && item.is_allowed === 1));
        // setIsDelete(manageBoxes.some(item => item.name.trim() === 'Delete' && item.is_allowed === 1));
        setIsAdd(true);
        setIsView(true);
        setIsEdit(true);
        setIsDelete(true);
    };

    useEffect(() => {
        let TransactionFilterObj = {
            searchtext: "",
            pageNo: 0,
            pageSize: 10,
            sortOrder: "desc",
            sortColumn: "created_on",
            tableName: "",
        };
        handlePermissions();
        getProductList(TransactionFilterObj)
    }, [type]);

    if (type === 'View' || type === 'Edit') {
        return (
            <>
                <Product rowData={productRowData} type={type} setType={setType} />
            </>
        )
    }

    return (
        <>
            {loader ? <Loader /> : <div>
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <input
                            type="text"
                            className="form-control searchField"
                            placeholder="Search products..."
                            value={searchText}
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                    <Button onClick={() => navigate('/product')} disabled={!isAdd} className="btn-sm btn-primary btn-rounded">
                        <RiAddCircleFill size={26} color='black' />
                    </Button>
                </div>
                <div className="row">
                    {productData.length > 0 ? productData.map((val) => {
                        return (
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6" key={val.id}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-end py-2">
                                            <ActionCellRenderer data={val} />
                                        </div>
                                        <div className="new-arrival-product">
                                            <div className="new-arrivals-img-contnent">
                                                <img className="img-fluid" src={![undefined, 'undefined', null, 'null', ''].includes(val.product_url) ? val.product_url : 'https://s3.amazonaws.com/rpx-dev-ext-images-qr/NoImageFound.png'} alt={val.name} />
                                            </div>
                                            <div className="new-arrival-content text-center mt-3">
                                                <h4 className="fw-bold text-black">{val.name}</h4>
                                                <p className="card-desc">{val.desc}</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    {val.price && <p className="product-price">${val.price}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : <div>
                        <h3 className="">No product found!</h3>
                    </div>}
                </div>
                <Modal className="fade" show={modal} centered>
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to delete?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {productRowData && productRowData[0].name}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-sm"
                            variant="danger light"
                            onClick={() => setModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            className="btn-sm"
                            variant="primary"
                            onClick={() => handleProductDelete()}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            }
        </>
    );
};

export default ProductList;
