/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import loadable from "@loadable/component";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import moment from "moment";
import request from "../../../services/AxiosInstance";
import { AgGridReact } from "ag-grid-react";
import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import 'ag-grid-enterprise';
import RevenueByPeriod from "./RevenueByPeriod";

ModuleRegistry.registerModules([ServerSideRowModelModule]);

let TransactionFilterObj = {
    pageNo: 1,
    pageSize: 5,
};

const VolumeByDay = loadable(() => import("./VolumeByDay"));
const VolumeByTime = loadable(() => import("./VolumeByTime"));
const Utilization = loadable(() => import("./Utilization "));
const TurnOver = loadable(() => import("./TurnOver"));
const Adherence = loadable(() => import("./Adherence"));


const Dashboard = () => {
    const gridRef = useRef();
    const [assignedData, setAssignedData] = useState([]);
    const gridOptions = {
        rowModelType: 'serverSide',
        gridBodyCls: 'ag-layout-auto-height'
    };

    const onGridReady = (params) => {
        const datasource = getServerSideDatasource();
        params.api.setServerSideDatasource(datasource);
    };

    const getServerSideDatasource = () => {
        return {
            getRows(params) {
                const sizeAll = Math.floor(params.request.startRow / 5);
                TransactionFilterObj.pageNo = sizeAll === 0 ? 1 : sizeAll + 1;

                apiCall();

                function apiCall() {
                    request('post', 'apis/i-ecom/handleorders/box/BoxList', TransactionFilterObj)
                        .then(response => {
                            if (response && response.results) {
                                if (response.results.length > 0) {
                                    setAssignedData(response.total);
                                    params.success({ rowData: response.results, rowCount: response.total });
                                } else {
                                    params.success({ rowData: [], rowCount: 0 });
                                }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            params.fail();
                        })
                }
            }
        };
    }

    const ImageCellRenderer = (props) => {
        return (
            <img
                src={props.value}
                alt=""
                style={{ width: '50px', height: '50px' }}
            />
        );
    };

    const ActionCellRenderer = ({ data }) => {
        const [detailsModal, setDetailsModal] = useState(false);

        const showModal = () => {
            setDetailsModal(true)
        }
        const handleClose = () => {
            setDetailsModal(false)
        }

        return (
            <>
                <Button variant="link" className='pr-0' onClick={showModal}>
                    <i className="fa-solid fa-bars"></i>
                </Button>
                <Modal className="fade" size="xl" show={detailsModal} centered>
                    <Modal.Header className="justify-content-between">
                        <Modal.Title><span>Box details</span></Modal.Title>
                        <Modal.Title><i class="fa-solid fa-xmark" onClick={handleClose}></i></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <Table className="m-0" responsive>
                            <thead>
                                <tr>
                                    <th className="width80">
                                        <strong>Picture</strong>
                                    </th>
                                    <th>
                                        <strong>Store</strong>
                                    </th>
                                    <th>
                                        <strong>Name</strong>
                                    </th>
                                    <th>
                                        <strong>Description</strong>
                                    </th>
                                    <th>
                                        <strong>Type</strong>
                                    </th>
                                    <th>
                                        <strong>City</strong>
                                    </th>
                                    <th>
                                        <strong>Box Id</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    <tr key={data.id}>
                                        <td><img src={data.picture_url} alt="picImg" width={'50px'} height={'50px'} /></td>
                                        <td>{data.account && data.account.name}</td>
                                        <td>{data.name}</td>
                                        <td>{data.description}</td>
                                        <td>{data.type && data.type.name}</td>
                                        <td>{data.address && data.address.city}</td>
                                        <td>{data.kiosk_box_id}</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='pr-0 btn-success btn-sm' onClick={handleClose}>CLOSE</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    const StatusCellRenderer = (params) => {
        const status = params.value === 0 ? 'Offline' : params.value === 1 ? 'Active' : params.value === 2 ? 'Pending' : 'New';
        const badgeColor = params.value === 0 ? 'badge-info' : params.value === 1 ? 'badge-primary' : params.value === 2 ? 'badge-warning' : 'badge-secondary';
        return (
            <span className={`badge badge-rounded ${badgeColor}`}>
                {status}
            </span>
        );
    };

    const DateCellFormatter = (params) => {
        if (params && params.data && params.data.lastCommunicationDate) {
            const strTimezone = sessionStorage.getItem('timeZone')
            const sessionTimeZone = strTimezone.split(') ')[1];
            const timeZone = ![undefined, 'undefined', null, 'null', ''] ? sessionTimeZone : 'America/Los_Angeles';
            const date = moment(new Date(params.data.lastCommunicationDate));
            return date.tz(timeZone).format('MM/DD/YYYY hh:mm:ss A');
        } else {
            return ''
        }
    }

    const handlePageSelect = () => {
        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshInfiniteCache()
        }
    }

    const TableHeader = [
        { headerName: '', field: "picture_url", sortable: false, filter: false, cellRenderer: ImageCellRenderer },
        { headerName: 'Store', field: "account.name", sortable: false, filter: false, },
        { headerName: "Location", field: "name", sortable: false, filter: false, },
        { headerName: "Last Communication", field: "lastCommunicationDate", sortable: false, filter: false, cellRenderer: DateCellFormatter, },
        { headerName: "Utilization", field: "box_Utilization", sortable: false, filter: false, valueFormatter: (params) => `${params.value}%` },
        { headerName: "Available Bins", field: "available_Bins", sortable: false, filter: false, },
        { headerName: "Status", field: "status", sortable: false, filter: false, cellRenderer: StatusCellRenderer, },
        { headerName: "", field: "", sortable: false, filter: false, cellRenderer: ActionCellRenderer },
    ];

    return (
        <>
            <div className="row">
                <div className="col-xl-6">
                    <Utilization />
                </div>
                <div className="col-xl-6">
                    <RevenueByPeriod />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div id="myGrid" className={"ag-theme-alpine"} style={{ boxSizing: "border-box", width: "100%" }}>
                                <div className="d-flex align-items-center justify-content-end">
                                    <span className='badge badge-rounded badge-success m-2'>
                                        {assignedData} Kiosks
                                    </span>
                                    <Dropdown onSelect={handlePageSelect}>
                                        <Dropdown.Toggle variant='outline-primary' size='sm'>
                                            <i className="fa-solid fa-bars"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey='5'><i class="fa-solid fa-arrows-rotate"></i> Update Data</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <AgGridReact
                                    ref={gridRef}
                                    sideBar={false}
                                    suppressMenuHide={true}
                                    columnDefs={TableHeader}
                                    pagination={true}
                                    paginationPageSize={5}
                                    paginationPageSizeSelector={false}
                                    cacheBlockSize={5}
                                    onGridReady={onGridReady}
                                    gridOptions={gridOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <VolumeByDay />
                </div>
                <div className="col-6">
                    <VolumeByTime />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <TurnOver />
                </div>
                <div className="col-6">
                    <Adherence />
                </div>
            </div>
        </>
    );
};

export default Dashboard;
