import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logotext from "../../images/iLocalBox__Logo.png";
import request from "./../../services/AxiosInstance";
import ConfigureAmplify from '../../common/awsConfig ';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import USER_ROLES from "../../common/userRoles";
import { setUser } from "../../redux/userSlice";
import { setAuth } from "../../redux/authSlice";
import { BtnLoader } from "../components/bootstrap/Loader";
import axios from "axios";
import ResetPassword from "./ResetPassword";

function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loader, setLoader] = useState('')
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState('');
	const [resetPassword, setResetPassword] = useState(false);
	const [userObj, setUserObj] = useState('');
	const [errors, setErrors] = useState({ email: false, password: false });

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const checkValidation = () => {
		if (!email) {
			setErrors(prevState => ({ ...prevState, email: true }));
			setErrorMessage("Email is Required")
			return false;
		}
		if (!emailRegex.test(email)) {
			setErrors(prevState => ({ ...prevState, email: true }));
			setErrorMessage("Invalid Email")
			return false;
		}
		if (!password) {
			setErrors(prevState => ({ ...prevState, password: true }));
			setErrorMessage("Password is Required")
			return false;
		}
		setErrors(false);
		return true;
	};

	const afterSignInHandler = async () => {
		let userObj = { username: email };
		const userData = await request('post', 'UpdateLastLoginDateTime', userObj)
		if (userData.message) {
			toast.error(userData.message);
			setLoader(false);
			return;
		}
		const parentAccount = userData && userData.res && userData.res.parentAccount && userData.res.parentAccount.parentAccount
		if (parentAccount.name) {
			sessionStorage.setItem('parentAccountName', parentAccount.name);
			sessionStorage.setItem('parentAccountId', parentAccount.id);
			sessionStorage.setItem('type_id', parentAccount.type_id);
			sessionStorage.setItem('timeZone', parentAccount.timezone ? parentAccount.timezone : '');
		} else {
			sessionStorage.setItem('parentAccountName', '');
			sessionStorage.setItem('parentAccountId', '');
			sessionStorage.setItem('type_id', '');
			sessionStorage.setItem('timeZone', '');
		}

		//Get Permissions for Login user
		await axios.get(process.env.REACT_APP_BASEURL + 'getRolesPermissions', {
			headers: {
				Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken.jwtToken,
				'Content-Type': 'application/json',
			},
		}).then((res) => {
			const permissionData = res.data
			if (permissionData.permission) {
				sessionStorage.setItem('permissions', JSON.stringify(permissionData.permission));
			}
			if (permissionData.user && permissionData.user.roles && permissionData.user.roles.length > 0) {
				const roles = permissionData.user.roles;
				sessionStorage.setItem('permissionUser', JSON.stringify(permissionData.user.roles[0].id));

				roles.forEach((element) => {
					if (element.id === USER_ROLES.PHARMACIST) {
						sessionStorage.setItem('roleId', JSON.stringify(element.id));
					}
					if (element.id === USER_ROLES.ADMIN) {
						sessionStorage.setItem('admin', JSON.stringify(element.id));
					}
					if (element.id === USER_ROLES.SUPERADMIN) {
						sessionStorage.setItem(
							'superadmin',
							JSON.stringify(element.id)
						);
					}
					if (element.id === USER_ROLES.DELIVERYTECH) {
						sessionStorage.setItem(
							'deliverytech',
							JSON.stringify(element.id)
						);
					}
				});
			}
		}).catch((err) => toast.error(err.message))
		navigate("/dashboard ");
		dispatch(setAuth({ isAuth: true }));
		setLoader(false);
	};

	const handleLogin = async (event) => {
		event.preventDefault();
		const isValid = checkValidation();
		if (!isValid) { return; }
		const userObj = { username: email };
		try {
			setLoader(true)
			const responseData = await request("POST", "getresetpwdatefromuser", userObj);
			const passwordExpiry = new Date(responseData.password_reset_on);
			passwordExpiry.setDate(passwordExpiry.getDay() + 180);
			if (!responseData) {
				setErrorMessage("Invalid UserName")
				setErrors(true);
				return;
			} else if (responseData && !responseData.is_enabled) {
				setErrorMessage("We found your account, but it has been disabled. Please contact your administrator to request access. UserName")
				setErrors(true);
				return;
			} else if (responseData && responseData.is_mfa_required) {
				console.log("mfa required")
			} else if (passwordExpiry <= Date.now()) {
				Auth.forgotPassword(email).then((data) => {
					toast.error("Your password has been expired")
					setLoader(false);
					navigate('/forget-password', { state: email })
				})
			} else {
				Auth.signIn(email, password).then(async (user) => {
					if (user.challengeName === 'NEW_PASSWORD_REQUIRED' ||
						user.challengeName === 'PASSWORD_VERIFIER') {
						setResetPassword(true)
						setUserObj(user)
						setLoader(false)
					} else {
						sessionStorage.setItem('user', JSON.stringify(user));
						dispatch(setUser({ user: JSON.stringify(user) }));
						afterSignInHandler()
					}
				}).catch((err) => {
					toast.error(err.message);
					setLoader(false)
					return;
				})
			}
		} catch (err) {
			toast.error(err.message);
			setLoader(false)
			return;
		}
	};

	const handleNavigate = () => {
		if (!email) {
			setErrors(prevState => ({ ...prevState, email: true }));
			setErrorMessage("Email is Required")
			return false;
		}
		if (!emailRegex.test(email)) {
			setErrors(prevState => ({ ...prevState, email: true }));
			setErrorMessage("Invalid Email")
			return false;
		}
		navigate('/forget-password', { state: email })
	}

	useEffect(() => {
		for (let key of Object.keys(localStorage)) {
			localStorage.removeItem(key);
		}
		for (let key of Object.keys(sessionStorage)) {
			sessionStorage.removeItem(key);
		}
		ConfigureAmplify()
	}, []);

	return (
		<>
			{!resetPassword ? <div className="login-form-bx">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-6 col-md-7 box-skew d-flex">
							<div className="authincation-content">
								<Link to="#" className="login-logo">
									<img src={logotext} alt="" className="logo-text ms-1" />
								</Link>
								<div className="mb-4">
									<h3 className="mb-1 font-w600">Welcome to iLocalBox</h3>
									<p className="">Sign in by entering information below</p>
								</div>
								<form onSubmit={(e) => handleLogin(e)}>
									<div className="form-group">
										<label className="mb-2 ">
											<strong className="">Email</strong>
										</label>
										<input
											type="email"
											className="form-control"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
										{errors.email && (
											<div className="text-danger fs-12">{errorMessage}</div>
										)}
									</div>
									<div className="form-group">
										<label className="mb-2 ">
											<strong className="">Password</strong>
										</label>
										<input
											type="password"
											className="form-control"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										{errors.password && (
											<div className="text-danger fs-12">{errorMessage}</div>
										)}
									</div>
									<div className="new-account mt-2">
										<p className="mb-0 text-blue" onClick={() => handleNavigate()}>
											Forget Password ?
										</p>
									</div>
									<div className="text-center"> <button type="submit" className="btn btn-primary btn-block">
										{loader ? <BtnLoader /> : "Sign In"}
									</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-6 col-md-5 d-flex box-skew1"></div>
					</div>
				</div>
			</div> : <ResetPassword setResetPassword={setResetPassword} user={userObj} />}
		</>
	);
}

export default Login;
