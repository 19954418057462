import { createSlice } from '@reduxjs/toolkit';

const initialState = { isAuth: false };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth(state, action) {
            state.isAuth = action.payload.isAuth;
        },
    },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;
