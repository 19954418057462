import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from "./userSlice"
import authReducer from "./authSlice"
import menuReducer from "./menuSlice"
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({ userReducer, authReducer,menuReducer });
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
