import { createSlice } from '@reduxjs/toolkit';

const initialState = { sideMenu: false };

const menuSlice = createSlice({
    name: 'sideMenu',
    initialState,
    reducers: {
        setSideMenu(state, action) {
            state.sideMenu = action.payload.sideMenu;
        },
    },
});

export const { setSideMenu } = menuSlice.actions;
export default menuSlice.reducer;
